export default httpClient => ({
  index: async ({
    nome,
    email,
    assunto,
    mensagem
  }) => {
    const response = await httpClient.post('/fale-conosco', {
      nome,
      email,
      assunto,
      mensagem
    })

    let errors = null
    console.log(response)
    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.error_message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  },
  cancelar: async ({
    nome,
    sobrenome,
    cpf,
    email,
    mensagem
  }) => {
    const response = await httpClient.post('/fale-conosco/cancelar', {
      nome,
      sobrenome,
      cpf,
      email,
      mensagem
    })

    let errors = null
    console.log(response)
    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.error_message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  }
})
