export default httpClient => ({
  login: async ({
    cpf,
    senha
  }) => {
    const response = await httpClient.post('/authenticate', {
      cpf,
      senha
    })

    let errors = null

    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.cpf[0]
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  }
})
