export default httpClient => ({
  index: async () => {
    const response = await httpClient.get('/depoimentos')

    let errors = null

    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  }
})
