export default httpClient => ({
  index: async ({
    nome,
    sobrenome,
    celular,
    senha,
    token
  }) => {
    const response = await httpClient.post('/cadastro/editar-perfil', {
      nome,
      sobrenome,
      celular,
      senha,
      token
    })

    let errors = null
    console.log(response)
    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.error_message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  }
})
