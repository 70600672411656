export default httpClient => ({
  cadastro: async ({
    nome,
    sobrenome,
    email,
    dataNascimento,
    cpf,
    senha,
    confirmacaoSenha,
    celular,
    concordo,
    parceiro
  }) => {
    const response = await httpClient.post('/cadastro', {
      nome,
      sobrenome,
      email,
      dataNascimento,
      cpf,
      senha,
      confirmacaoSenha,
      celular,
      concordo,
      parceiro
    })

    let errors = null

    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      if (errorsJson.errors.sms[0]) {
        errors = {
          status: 999,
          statusText: errorsJson.errors.sms[0]
        }
      } else {
        errors = {
          status: response.request.status,
          statusText: errorsJson.errors.cpf[0]
        }
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  },

  sms: async ({
    numero1,
    numero2,
    numero3,
    numero4,
    numero5,
    numero6,
    token
  }) => {
    const response = await httpClient.post('/cadastro/sms', {
      numero1,
      numero2,
      numero3,
      numero4,
      numero5,
      numero6,
      token
    })

    let errors = null

    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      console.log(response.request.status, errorsJson)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.numero1[0]
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  },

  usuario: async ({
    token
  }) => {
    const response = await httpClient.post('/cadastro/usuario', {
      token
    })

    let errors = null

    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.numero1[0]
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  }
})
