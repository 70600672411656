export default httpClient => ({
  index: async ({
    token
  }) => {
    const response = await httpClient.post('/pagamentos', {
      token
    })

    let errors = null

    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  },
  cupom: async ({
    code,
    token
  }) => {
    const response = await httpClient.post('/pagamentos/cupom', {
      code,
      token
    })

    let errors = null

    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  }
})
