export default httpClient => ({
  index: async () => {
    const response = await httpClient.get('/promocao')

    let errors = null

    if (!response.data) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  },
  prepara: async ({
    qtd,
    token
  }) => {
    const response = await httpClient.post('/venda/prepara', {
      qtd,
      token
    })

    let errors = null
    // console.log(response.data.id)
    if (!response.data.id) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.error_message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  },
  preparaPix: async ({
    qtd,
    token
  }) => {
    const response = await httpClient.post('/venda/preparaPix', {
      qtd,
      token
    })

    let errors = null
    // console.log(response.data.id)
    if (!response.data.id) {
      const errorsJson = JSON.parse(response.request.response)
      errors = {
        status: response.request.status,
        statusText: errorsJson.errors.error_message
      }
    }

    return {
      data: response.data,
      errors: errors
    }
  }
})
