import axios from 'axios'
import { router } from '../router'
import { setGlobalLoading } from '../store/global'
import Cadastro from './cadastro'
import Login from './login'
import Premios from './premios'
import Depoimentos from './depoimentos'
import Duvidas from './duvidas'
import Promocao from './promocao'
import Termo from './termo'
import FaleConosco from './faleconosco'
import Ganhadores from './ganhadores'
import Publicacoes from './publicacoes'
import Pagamentos from './pagamentos'
import Enquetes from './enquetes'
import EditarPerfil from './editar-perfil'

const ENVs = {
  prod: 'https://admin.redeamericapremios.com.br/api',
  dev: '',
  local: 'http://127.0.0.1:8000/api'
}

const httpClient = axios.create({
  baseURL: ENVs.prod
})

httpClient.interceptors.request.use(config => {
  setGlobalLoading(true)

  return config
})

httpClient.interceptors.response.use(response => {
  setGlobalLoading(false)
  return response
}, (error) => {
  const canThrowAnError = error.request.status === 0 || error.request.status === 500
  if (canThrowAnError) {
    setGlobalLoading(false)
    throw new Error(error.message)
  }

  if (error.response.status === 401) {
    setGlobalLoading(false)
    router.push({ name: 'Login' })
  }

  setGlobalLoading(false)
  return error
})

export default {
  cadastro: Cadastro(httpClient),
  login: Login(httpClient),
  premios: Premios(httpClient),
  depoimentos: Depoimentos(httpClient),
  duvidas: Duvidas(httpClient),
  promocao: Promocao(httpClient),
  termo: Termo(httpClient),
  faleconosco: FaleConosco(httpClient),
  ganhadores: Ganhadores(httpClient),
  pagamentos: Pagamentos(httpClient),
  publicacoes: Publicacoes(httpClient),
  enquetes: Enquetes(httpClient),
  editarperfil: EditarPerfil(httpClient)
}
